import './bootstrap';
import 'flowbite';
import { showToast } from './toasts';

function initLazyLoading() {
    const lazyImages = document.querySelectorAll(".lazy:not(.lazy-loaded)");
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                img.src = img.getAttribute("data-src");
                img.classList.remove("lazy");
                img.classList.add("lazy-loaded");
                observer.unobserve(img);
            }
        });
    });
    lazyImages.forEach(img => {
        observer.observe(img);
    });
}

const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const tooltipTrigger = entry.target;
            new Tooltip(tooltipTrigger);
            observer.unobserve(tooltipTrigger);
        }
    });
});

document.querySelectorAll('[data-tooltip-target]').forEach(tooltipTrigger => {
    observer.observe(tooltipTrigger);
});
export function initializePopovers() {
    const popoverTriggers = document.querySelectorAll('[data-popover-target]');
    popoverTriggers.forEach(trigger => {
        const targetId = trigger.getAttribute('data-popover-target');
        const target = document.getElementById(targetId);
        if (target && !trigger.hasAttribute('data-popover-initialized')) {
            const popover = new Popover(target, trigger);
            trigger.setAttribute('data-popover-initialized', 'true');
        }
    });
}

window.showToast = showToast;
window.initLazyLoading = initLazyLoading;
document.addEventListener('DOMContentLoaded', () => {
    initLazyLoading();
    initializePopovers();
});
